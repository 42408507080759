.font20{
    font-size: 16px;
    margin-top: -10px;
}
h5{
    padding: 0px;
    margin: 0px;
}
.topBar{
    margin-top: 65px;
}
.button{
    background-color: #000000;
    color: #ffffff;
}
.buttonRed{
    background-color: #FF2800;
    color: #ffffff;
}
p{
    margin: 0px; padding: 0px;
}
.red{
    color: red;
}
.green{
    color: green;
}
.device{
    background: none;
    border: 0px solid;
    font-size: 8px;
}
.pelanggan{
    background: none;
    border: 0px solid;
    font-size: 10px;
    font-weight: bold;
    margin-top: 5px;
}
.info{
    background: none;
    border: 0px solid;
    font-size: 8px;
    margin-top: 5px;
}

.textFieldLeft{
    margin-right: 10px;
}
.textFieldRight{
    margin-left: 10px;
}

.innerForm{
    padding: 10px;
}

.inputField{
    width: 100%;
}
.error{
    color: #D61818;
    font-size: 12px;
    font-style: italic;
    padding: 5px;
}
.formHeader{
    position: fixed;
    width: 100%;
    z-index: 999999;
    padding: 0 20px;
    box-sizing: border-box;
}
.formContent{
    overflow: scroll; 
}
.menuLabel{
    background-color: #B4DAFF;
    padding: 10px 5px 5px 15px;
    border-bottom: 1px solid #cccccc;
}